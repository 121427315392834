//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LegacyMixin from '@/mixins/legacy'

export default {
  mixins: [LegacyMixin],
  methods: {
    showContactModal() {
      this.$nuxt.$emit('show-contact-modal', { form: 'default' })
    },
    switchLanguage() {
      if (this.$i18n.locale == 'en') {
        window.location.href = 'https://outracoisa.co' + this.$route.path
      } else {
        window.location.href = 'https://otherdigital.co' + this.$route.path
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
