//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LegacyMixin from '@/mixins/legacy'

export default {
  mixins: [LegacyMixin],
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    $nuxt.$emit('clear-metaballs')
  },
  computed: {
    title() {
      return this.$t('erro') + ' ' + this.error.statusCode
    },
    subtitle() {
      if (this.error.statusCode == 404) {
        return this.error.message != 'This page could not be found'
          ? this.$t(this.error.message)
          : this.$t('misc.page_not_found')
      } else {
        return this.$t('misc.generic_error_message')
      }
    },
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError

    return {
      title,
    }
  },
}
