export default {
    methods: {
        origin(path = "") {
            if (this.$i18n.locale == 'en') {
                return 'https://otherdigital.co' + path
            } else {
                return 'https://outracoisa.co' + path
            }
        }
    }
}